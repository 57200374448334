import { render, staticRenderFns } from "./LogoHeader.vue?vue&type=template&id=42a15847&scoped=true&"
import script from "./LogoHeader.vue?vue&type=script&lang=js&"
export * from "./LogoHeader.vue?vue&type=script&lang=js&"
import style0 from "./LogoHeader.vue?vue&type=style&index=0&id=42a15847&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a15847",
  null
  
)

export default component.exports